<template>
 <div>
    <vue-modal-2 :modalSize="'lg'" :noFooter=true :noHeader=true ref="xxx" name="xxx" style='z-index: 999;'>
       <div :style="getAppStyle()+'height: calc(40vH) !important; border: 2pt inset grey;'">
       <div :style="getAppStyle()" class='appHead'>
             <div :style="'display: inline-block; float:left; width: 100%; color: '+getFGColor('#44c','#aaf')+'; font-weight: bold; padding-right: 5pt; font-size: 10pt;'">
              <div :style="'float:left; padding-left: 5pt; padding-top: 4pt; background-color: transparent !important;'" >
              {{gridAds.detail.name}}
              </div>
              <div :style="'float:right; padding-right: 2pt; background-color: transparent !important;'" >
                  <WindowCloseBN @click="close"/>
		      </div>
            </div>
       </div> <!-- appHead -->
       <div style='display: block; float: left; border-bottom: 1pt solid grey; width: 100% !important;'>

		
      </div>
      <div :style="getAppStyle()+'display: block; float: left; width: 100% !important; overflow-y: auto;'">
        <br/>
       
        <div class="lb">BreakType:</div><div class="val">{{gridAds.breakTypes.find(p=>p.id==data.breakType).name}}</div> 
        <div class="lb">Interruption:</div><div class="val">{{data.noInGrid}}</div> 
        <div class="lb">Ratetype:</div><div class="val">{{gridAds.ratecardTypes.find(p=>p.id==data.ratecardTypeId).name}}</div> 
        <div class="lb">Label:</div><div class="val"><InputText @enter="saveAndClose" v-model="data.name"/></div>
        <div class="lb">Time:</div><div class="val"><InputTime @enter="saveAndClose" v-model="data.time"/></div> 
        <div class="lb">Duration/Capacity (sec):</div><div class="val"><InputInt @enter="saveAndClose" v-model="data.durationInSeconds"/></div> 
        <div class="lb">Id:</div><div class="val"><InputInt :disabled='true' @enter="saveAndClose" v-model="data.id"/></div> 
        
        <div class="lb">Pricegroup:</div><div class="val">
                    <vSelect :appendToBody="true" placeholder='choose pricegroup' :options="rateCardsOption" style='width:100%' v-model="selectedRC" @input='setPriceGroup'>
			          <template :style='getRCOptionStyle(colour)' #option="{ id, label, price, colour }">
      					<div :style='getRCOptionStyle(colour)'>{{label}} {{Number(price).toLocaleString(myLocale())}} {{currency.shortname}}</div>
    				  </template>
			        </vSelect>
        </div> 
        <div class="lb"></div><div class="val">PG {{data.pg}} ({{Number(rateCardsOption.find( r=> r.pg===data.pricegroup).price).toLocaleString(myLocale())}} {{currency.shortname}})</div> 
        
         

        <br/>
        <br/>
        <div style='float: right; padding-right: 10pt;'>
        
        <button class="button" type="button" @click="close">CLOSE</button>
        <button :disabled="!data.id" class="button" type="button" @click="removeIt">DELETE</button>
        <button class="button" type="button" @click="saveIt">SAVE</button>
        </div>
        
      </div>
    </div>
   </vue-modal-2>
 </div>
</template>
<script>
import {HTTP, WSLURL, APIURL, APIURLB, APIURLA, port_LB, myLocale, showError, getServerInfo, xApi} from '@/variables.js';
import { getAppStyle, initAppMode, getBGStyle2, getFGColor } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputTime from '@/components/inputElements/InputTime2';
import InputInt from '@/components/inputElements/InputInt';
import InputText from '@/components/inputElements/InputText';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: {
    generalInfo: { type: String },
  },
  data () {
    return {
    	showMe: false,
    	data: null,
    	gridAds: null,
    	currency: {},
    	rateCardsOption: [],
    	selectedRC: {},
    	getAppStyle,
    	getFGColor,
    	getBGStyle2,
    	myLocale
    }
  },
  components : {
	  WindowCloseBN, InputTime, InputText, InputInt, 
	  vSelect
  },
  computed: 
  {
    
  },
  created() 
  {
  		initAppMode();
  		this.$vm2.open('xxx');
  		 this.$nextTick(function () {
  		this.$vm2.close('xxx');
  		 });
  },
  
  methods: 
  {
	  open( data, gridAds, currency, rateCardsOption, label) {
      	this.showMe = true;
      	this.data = data;
      	if ( !this.data.label )
      	{
      		this.data.label = label;
      	}
      	this.gridAds = gridAds;
      	this.currency = currency;
      	this.rateCardsOption = rateCardsOption;
      
        this.selectedRC = this.rateCardsOption.find( r=> r.pg===this.data.pricegroup);
        
      	this.$vm2.open('xxx');
 		 this.$nextTick(function () {
 		    this.$vm2.close('xxx');
 		    this.$vm2.open('xxx');
 		 });
      	
      	
      },
      setPriceGroup(pg)
      {
    	  this.data.pg = pg.pg;
      },
      saveAndClose()
      {
    		this.saveIt();
    		this.close();
      },
      saveIt()
      {
    	  this.$emit('changed', this.data, this.gridAds);
      },
      removeIt()
      {
    	  
    	  this.$emit('remove', this.data, this.gridAds);
    	  this.close();
      },
	  close() {
    	  this.$vm2.close('xxx');
      	  //this.$emit("changed", this.data, this.gridAds)
      },
      getRCOptionStyle(colour) {
       	return "color: #000; font-weight: bold; font-size: 9pt; margin: 0pt !important; height: 100% !important; width: 100; padding: 0pt; background-color: "+colour+";";
      },
  },
  watch: 
  {
       
  }
}
</script>
<style lang="scss" scoped>
.appHead {
	height: 22pt;
}

.tooltipClass:hover {
  background: #CCC;
}
.lb {
 display: inline-flex;
 width: 30%;
 padding-left: 9pt;
 font-size: 10pt;
 font-weight: bold; 
 margin-bottom: 3pt;
}
.button {
 height: 20pt;
}
.val {
 display: inline-flex;
 width: 60%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
</style>